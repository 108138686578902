// 
// maintenance.scss
//

.svg-computer {
    stroke-dasharray: 1134;
    stroke-dashoffset: -1134;
    animation: draw-me 5s infinite;
    animation-direction: normal;
    height: 160px;
}

@keyframes draw-me {
    from {
        stroke-dashoffset: -1134;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .svg-computer {
        stroke-dasharray: 0;
        stroke-dashoffset: 0;
        animation: none;
        animation-direction: normal;
    }
}


.L1 {
    color: #aaa;
    font-weight: 500;
}


.H1 {
    color: #3d3d3d;
    font-weight: bold;
    font-size: 20px;
}


.zIndexxx {
    z-index: 10000 !important;
}


body {
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
}


////customerPayDetails

.container-customerPage {
    width: 100%;
    // height: calc(100vh - 76.61px);
    // max-height: calc(100vh - 76.61px);
    overflow: hidden;
}


.payContainer {
    width: 50%;
    max-width: 50%;
    min-width: 50%;
    // overflow-wrap: break-word;
    border-right: solid 0.5px rgb(222, 216, 216);
    height: calc(100vh - 76.61px);
    padding-right: 2%;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
}

.salesConteiner {
    width: 80%;
    max-width: 80%;
    min-width: 80%;
}

.salesItem {
    background-color: white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    color: #3d3d3d;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.saleFooter {
    width: 100%;
    min-width: 100%;
    padding: 10px;
    background-color: #1990ff;
}

.xxx {
    position: fixed;
    width: 390px;
    left: calc(50% - 77px);
    background-color: white;
    border: solid 0.3px rgba($color: #3d3d3d, $alpha: 0.5);
    align-items: center;
    bottom: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}